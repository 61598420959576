<template>
  <Layout ref="main">
    <div class="row">
      <div class="col-12 my-3">
        <ul class="list-group mb-2">
        <li class="list-group-item  mb-1 py-2 rounded" :class="{'bg-dark': value.language==selectedLang.language}" v-for="(value, index) in languages" :key="index">
          <a href="javascript:void(0)" class="fw-medium text-body d-flex align-items-center custom-link" 
          :title="value.title" @click="changeLang(value)" >
          <span><img :src="value.flag" height="18" class="me-2 my-1"></span>  
          <span :class="{'text-white': value.language==selectedLang.language}" >{{(value.title)}}</span>
          <span></span>
        </a>    
        </li>
      </ul>
      </div>
    </div>
    <Lottie :path="'animate/lottie.json'" :title="'Loading'" :info="'Please wait...'" :show="showLottie" ref="lottieFunc" />
    <Common ref="commonFunc"/>
  </Layout>
</template>
<script>
import Layout from "@/views/layouts/main";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
const CancelToken = axios.CancelToken;
let cancel;
/**
 * Starter page
 */
export default {
  components: { 
    Layout, 
    Lottie,
    Common
  },
  page() {
    return {
      title: this.$t('siteLang.ChangeLanguage'),
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  data() {
    return {
      accessToken:'',
      accessEmail:'',
      accessPhone:'',
      showLottie:false,
      title: '',
      items: [
        {
          text: appConfig.title,
          to: "/",
        },
        {
          text: "",
          to: "/me",
        },
        {
          text: '',
          active: true,
        },
      ],
      languages:[
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English"
        },
        {
          flag: require("@/assets/images/flags/indonesia.png"),
          language: "id",
          title: "Indonesia"
        }
      ],
      selectedLang:{}
    };
  },
  middleware: "authentication",
  mounted(){
    this.$refs.main.changeVerticalTopBar("me",true)
    this.$refs.main.setPageTitle(this.$t('siteLang.ChangeLanguage'))
    this.title = this.$t('siteLang.ChangeLanguage')
    this.items[1].text = this.$t('siteLang.MyProfile') 
    this.items[2].text = this.$t('siteLang.ChangeLanguage') 
    // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    // this.$refs.main.changeVerticalTopBar("bill",true)
    // this.$refs.main.setShowFooterCert(false)
    // this.$refs.main.setPageTitle('title')
    this.accessToken = this.$refs.commonFunc.getToken()
    this.accessPhone = this.$refs.commonFunc.getPhone()

    const Language = this.languages.find(lang => lang.language === this.$i18n.locale);
    this.selectedLang = Language
    //this.getData()
  },
  created(){
    
  },
  methods:{
    changeLang(value){
        if(cancel !== undefined) cancel('');
        this.$Progress.start();
        this.loading = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("phone",  this.accessPhone);
        bodyFormData.append("lang",  value.language);
        
        axios({
            method: "post",
            url: appConfig.APIHost + "controller/webapp/changeLang",
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
            cancelToken: new CancelToken(c => cancel = c),
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              this.$refs.commonFunc.setLang(value.language)
               this.$router.push({
                    name: "me",
              });
            }
            else if (resData.status == 401){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                this.$refs.commonFunc.clearData()
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    }
  }
};
</script>